

























































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { loadView, loadWidget, truncateContent } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import moment from 'moment-timezone';
import {
  IAPIKeys,
  IPaginate,
  IScheduledEvent,
  ISenderID,
  IUser,
} from '@/types/types';
import { Watch } from 'vue-property-decorator';
const authModule = namespace('auth');
const apiKeyModule = namespace('apiKeys');
const smsModule = namespace('sms');
const eventsModule = namespace('events');
@Component({
  name: 'Dashboard',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
    EventCalendar: loadView(`pages/events/components/EventCalendar`),
    CreateEventDialog: loadView(`pages/events/dialogs/CreateEventDialog`),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
  },
  filters: {
    timestamp(value: string, format?: string) {
      return moment(value).format(format ?? 'Do MMMM, YYYY');
    },
    truncateContent,
  },
})
export default class Dashboard extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @Getter('getResetFormState') resetFormState!: boolean;
  @apiKeyModule.Getter('getAPIKeys') accessKeys!: IAPIKeys;

  @smsModule.Getter('getSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;

  @authModule.Getter('getUserDetails') user!: IUser;
  @eventsModule.Getter('getAllEvents') events!: Array<IScheduledEvent>;
  @eventsModule.Getter('getEventDetails') eventDetails!: IScheduledEvent;
  @eventsModule.Getter('getAllPaginatedEvents')
  paginatedEventList!: Array<IScheduledEvent>;
  @eventsModule.Getter('getPagination') paginate!: IPaginate & {
    limit: number;
  };

  date = [];
  menu = false;
  query = '';
  picker = '';
  times: string[] = [];
  timezones: string[] = [];
  currentDate = '';

  incomingDateToCreateEvent: string | null = null;
  eventFromParent: {
    eventId: number | null;
    date: string | null;
  } | null = null;
  currentPaginatedPage = 1;

  addNewEvent(payload: any): void {
    this.$store.dispatch('events/schedule', payload);
  }

  @Watch('paginate')
  onPaginationChange(paginate: IPaginate): void {
    this.currentPaginatedPage = paginate.page ?? 1;
  }

  @Watch('currentPaginatedPage')
  onCurrentPageChange(page: number): void {
    this.$store.dispatch('events/paginatedList', {
      query: `?page=${page}&size=${
        this.paginate?.limit ?? 30
      }&isPaginated=true`,
    });
  }

  deleteEvent(): void {
    if (this.$route.query?.eventId) {
      let payload = {
        id: this.$route.query?.eventId,
        loading: {
          idx: 'delete',
          state: false,
        },
        view: this.$route.query?.view,
        date: decodeURIComponent(this.$route.query?.date as string),
      };
      this.$store.dispatch('events/delete', payload);
    }
  }

  openEventDialogFromParent(): void {
    this.incomingDateToCreateEvent = new Date().toISOString();
  }

  created(): void {
    this.currentDate = moment().format('YYYY-MM-DD');
    this.$store.dispatch('auth/me');
    this.picker = moment().format('YYYY-MM-DD');
    this.$store.dispatch('sms/listSenderID', { isApproved: true });

    if (this.$store.getters['getWindowSize'] <= 1355) {
      this.$store.dispatch('events/paginatedList', {
        query: `?page=1&size=30&isPaginated=true`,
      });
    } else {
      this.$store.dispatch('events/list', {
        query: `?activeDate=${moment().format('YYYY-MM-DD')}&isPaginated=false`,
      });
    }

    this.$store.dispatch('contact/list', {
      page: 1,
      limit: 30,
    });
    this.times = Array(96)
      .fill(0)
      .map((_time, index) =>
        moment()
          .set('hours', Math.floor(index / 4))
          .set('minutes', (15 * index) % 60)
          .format('hh:mm a')
      );
    this.$store.dispatch('checkCreditBalance');
    if (this.$route.query?.actionType && this.$route.query?.eventId) {
      const id = this.$route.query.eventId as string;
      this.open('edit', id);
    }
    window.addEventListener('resize', () => {
      this.$store.dispatch('updateWindowSize', window.innerWidth, {
        root: true,
      });
    });
    this.$store.dispatch('updateWindowSize', window.innerWidth, { root: true });
  }
}
